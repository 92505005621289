<template>
  <!--  凭证 - 资金类 - 关联开票申请编号 -->
  <div class="relationVoucher">
    <!-- 搜索条件 -->
    <el-form ref="pagination" inline :model="pagination">
      <el-form-item label="代收人：" label-width="80px" prop="name">
        <el-input
          v-model.trim="pagination.name"
          placeholder="请输入代收人名称"
          clearable
          @clear="(pagination.page = 1), getVoucherFilesList()"
        ></el-input>
      </el-form-item>

      <el-button
        type="primary"
        @click="(pagination.page = 1), getVoucherFilesList()"
        icon="el-icon-search"
        >搜索</el-button
      >
    </el-form>

    <!-- 文件夹凭证列表 -->
    <div class="voucher-files" v-loading="pagination.loading">
      <div
        class="file-box"
        v-for="item in pagination.list"
        :key="item.FolderId"
      >
        <div
          class="file-time"
          @click="toImageDetail(item, 'showSmallImageDetail')"
        >
          <span>代收人： {{ item.SendUserName }}</span>
          <p>{{ item.ModifyTime }}</p>
        </div>
        <p class="checkBtn">{{ item.FolderName }}</p>
        <el-button
          :type="item.IsRelated ? 'danger' : 'primary'"
          plain
          icon="el-icon-document-copy"
          size="small"
          class="relation-order"
          @click="relationOrDelete(item)"
          >{{ item.IsRelated ? "取消" : "" }}关联</el-button
        >
      </div>

      <!-- 分页 -->
      <el-pagination
        v-show="pagination.list != 0"
        class="pagination"
        style="width: 100%;"
        background
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>

      <!-- 暂无内容 -->
      <div
        class="noData"
        v-show="pagination.list == 0 && !pagination.loading"
      ></div>
    </div>

    <!-- 文件夹里面的 凭证图片 -->
    <!-- 返回 -->
    <div class="ImageDetail" v-if="flag.showSmallImageDetail">
      <el-button
        class="goBack"
        type="info"
        plain
        icon="el-icon-back"
        style="z-index: 99;"
        @click="hideImageDetail(false)"
        >返回文件夹</el-button
      >
      <ImageDetail
        @showImagePreview="showImagePreview"
        :userId="folderObj.SendUserID"
        :folderObj="folderObj"
        ref="imageDetail"
        :hideElement="true"
      ></ImageDetail>
    </div>

    <!-- 预览图片 -->
    <el-dialog
      :visible.sync="flag.previewImage"
      width="1200px"
      class="previewImage"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <el-image
        style="width: 100%;height:684px;"
        :src="previewImageObj.FileURL"
        fit="contain"
      ></el-image>
      <span slot="footer" class="dialog-footer">
        <span class="previewImage-time">
          上传时间: {{ previewImageObj.ModifyTime }}
        </span>
        <el-button @click="flag.previewImage = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetVoucherInfoAsync,
  PostSetEmFolderBindAsync,
  DeleteCancelEmFolderBindAsync,
} from "@/api/voucherManagement/voucherManagement.js";
import ImageDetail from "@/views/boss/waybill/voucherManagement/imageDetail";
export default {
  data() {
    return {
      flag: {
        showSmallImageDetail: false, // 文件夹中的图片凭证
        previewImage: false, // 预览图片
      },
      pagination: {
        loading: false,
        page: 1,
        pagesize: 10,
        total: 0,
        list: [],
        name: "", // 代收人名字
        invoiceTaskId: "", // 开票申请编号id
      },
      folderObj: "", // 选中的文件夹
      previewImageObj: {}, // 存储预览图片的相关数据
    };
  },
  props: ["invoiceTaskId"],
  components: {
    ImageDetail,
  },
  methods: {
    // 获取凭证文件夹列表
    getVoucherFilesList() {
      this.pagination.loading = true;
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        invoiceTaskId: this.invoiceTaskId,
        name: this.pagination.name,
      };

      GetVoucherInfoAsync(params)
        .then((res) => {
          let { TotalCount, DataList } = res.data;
          this.pagination.total = TotalCount;
          this.pagination.list = DataList;
        })
        .finally(() => {
          this.pagination.loading = false;
        });
    },

    // 文件夹分页
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getVoucherFilesList();
    },

    // 查看文件夹内凭证图片
    toImageDetail(obj, type) {
      this.folderObj = obj;
      this.flag[type] = true;
      this.$nextTick(() => this.$refs.imageDetail.getImageVoucher());
    },

    // 打开、关闭文件夹查看内部图片集合
    hideImageDetail(type) {
      this.flag.showSmallImageDetail = type;
    },

    // 打开子组件的图片， 预览
    showImagePreview(type, data) {
      this.previewImageObj = data;
      this.flag.previewImage = type;
    },

    // 关联开票申请编号 / 取消关联
    relationOrDelete(data) {
      let { FolderId, IsRelated } = data;
      this.$alert(
        `<strong><p style='text-align: left;'>${
          IsRelated ? "确认要取消关联吗？" : "是否关联开票申请编号？"
        }</p></strong>
          <p style='text-align: left;'>${
            IsRelated ? "取消关联后的凭证将会退回到未关联状态" : ""
          }</p>`,
        `${IsRelated ? "取消关联" : "关联开票申请编号"}`,
        {
          dangerouslyUseHTMLString: true,
          type: IsRelated ? "error" : "success",
          center: true,
          customClass: "relationOrDelete",
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          callback: (action) => {
            if (action == "confirm") {
              let ajaxFn = "";
              if (IsRelated) {
                ajaxFn = DeleteCancelEmFolderBindAsync(FolderId);
              } else {
                ajaxFn = PostSetEmFolderBindAsync(FolderId, [
                  this.invoiceTaskId,
                ]);
              }

              ajaxFn.then((res) => {
                this.$message({
                  message: `${IsRelated ? "取消" : ""}关联成功`,
                  type: "success",
                });
                this.getVoucherFilesList();
              });
            }
          },
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.relationVoucher {
  position: relative;

  .ImageDetail {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 99;
  }

  .voucher-files {
    width: 100%;
    box-sizing: border-box;
    margin-top: 2%;
    display: flex;
    flex-wrap: wrap;

    .file-box {
      width: 190px;
      height: 200px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 15px 55px 15px;

      .file-time {
        width: 100%;
        height: 140px;
        background: url("../../assets/image/voucherManagement/fileFolder.png")
          no-repeat;
        background-size: 100% 100%;
        position: relative;
        cursor: pointer;

        span {
          position: absolute;
          left: 16px;
          bottom: 40px;
        }

        p {
          position: absolute;
          left: 16px;
          bottom: 16px;
        }
      }

      .checkBtn {
        width: 100%;
        text-align: center;
        margin: 8px 0;
      }
    }
  }

  .noData {
    width: 320px;
    height: 270px;
    background: url("../../assets/image/voucherManagement/noData.png") no-repeat;
    background-size: 100% 100%;
    margin: 10% auto 0;
  }
}
</style>

<style lang="scss">
.relationVoucher {
  .ImageDetail {
    &.facilityBox {
      height: auto !important;
    }

    .voucher-images {
      .image-box {
        width: 180px !important;
        margin: 0 35px 70px 0 !important;

        .image {
          height: 140px !important;
          cursor: pointer;
        }
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.relationOrDelete {
  .el-message-box__title {
    justify-content: start;
  }

  .el-message-box__content {
    padding: 0px 45px;
  }

  .el-message-box__btns {
    text-align: right;
  }
}
</style>
