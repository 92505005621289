import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//查询凭证父级类型
export const GetEvidenceParentType = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Evidence/GetEvidenceParentType`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询凭证子集类型
export const GetEvidenceChildType = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Evidence/GetEvidenceChildType`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询凭证明细
export const GetEvidenceDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Evidence/GetEvidenceDetail`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//添加凭证
export const AddEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Evidence/AddEvidence`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传url凭证
export const AddEvidenceByUrls = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Evidence/AddEvidenceByUrls`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//删除/还原 凭证
export const UpdateEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Evidence/UpdateEvidence`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 凭证表格下载
export const DwonLoadEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Evidence/DwonLoadEvidence`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}